const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
        'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}

// *********** CREATE COURSE SECTION *********** //
export const createLectureContent = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/lecture-contents`, opts).then((data) => data.json())

    return response
}

// *********** FETCH ALL LECTURE CONTENTS THAT CONTAINS VIDEO *********** //
export const fetchLectureVideos = async () => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-contents?video_null=false`, opts).then((data) => data.json())

    return response
}
// *********** FETCH ALL COURSE SECTIONS (IN A COURSE) *********** //
export const fetchLectureContents = async (lectureId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-contents?course_lecture=${lectureId}`, opts).then((data) => data.json())

    return response
}

// *********** FETCH A SINGLE COURSE SECTION (BY ID) *********** //
export const fetchLectureContent = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-contents/${id}`, opts).then((data) => data.json())

    return response
}

// *********** UPDATE A COURSE SECTION (BY ID) *********** //
export const updateLectureContent = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/lecture-contents/${id}`, opts).then((data) => data.json())

    return response
}

// *********** DELETE A COURSE SECTION (BY ID) *********** //
export const deleteLectureContent = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-contents/${id}`, opts).then((data) => data.json())

    return response
}


// *********** FETCH LECTURE VIDEO VIEW *********** //
export const fetchLectureVideoView = async (lectureId, userId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-video-views?user=${userId}&lecture_content_video=${lectureId}`, opts).then((data) => data.json())

    return response
}


// *********** FETCH USER LECTURE VIDEO VIEWS *********** //

export const fetchUserLectureVideoViews = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/lecture-video-views?user=${userId}`, opts).then((data) => data.json())

    return response
}

// *********** CREATE LECTURE VIDEO VIEW *********** //
export const createLectureVideoView = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/lecture-video-views`, opts).then((data) => data.json())

    return response
}


// *********** UPDATE LECTURE VIDEO VIEWS *********** //
export const updateLectureVideoView = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/lecture-video-views/${id}`, opts).then((data) => data.json())

    return response
}