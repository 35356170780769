import React, { useState, useRef, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({ src, onCropComplete, aspect }) => {
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const imageRef = useRef(null);

    const onImageLoad = (e) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                width,
                height
            ),
            width,
            height
        );

        const pixelCrop = {
            unit: 'px',
            x: (crop.x / 100) * width,
            y: (crop.y / 100) * height,
            width: (crop.width / 100) * width,
            height: (crop.height / 100) * height
        };

        setCrop(crop);
        setCompletedCrop(pixelCrop);
        imageRef.current = e.currentTarget;
    };

    const getCroppedImg = useCallback((image, crop) => {
        if (!crop || !image) {
            return;
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = 'newFile.jpeg';
                const fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
    }, []);

    const onCropChange = (crop, percentCrop) => setCrop(percentCrop)

    const onCrop = useCallback(async () => {
        if (completedCrop && imageRef.current) {
            const croppedImageUrl = await getCroppedImg(imageRef.current, completedCrop);
            onCropComplete(croppedImageUrl);
        }
    }, [completedCrop, getCroppedImg, onCropComplete]);

    return (
        <Box>
            <ReactCrop
                crop={crop}
                onChange={onCropChange}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
            >
                <img src={src} onLoad={onImageLoad} alt="Crop" />
            </ReactCrop>
            <Button variant="outlined" onClick={onCrop}>Crop Image</Button>
        </Box>
    );
};

export default ImageCropper;
